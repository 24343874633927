import '../style/header.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import logo from '../images/logo.jpg';
import { ScrollTo } from "react-scroll-to";

const Header = () => {

	const useStyles = makeStyles((theme) => ({
		icon: {
			marginRight: theme.spacing(2),
		},
		heroContent: {
			padding: theme.spacing(6, 0, 6),
		},
		heroButtom: {
			margin: theme.spacing(4, 0, 2, 0),
		},
		inverted:{
			textDecoration: 'underline'
		},
		logo: {
			width: '140px',
			borderRadius: '50%',
			border: '1px solid #dedede',
			marginBottom: theme.spacing(2),
		},
	}));

	const classes = useStyles();

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: "#cccccc",
			},
			secondary: {
				main: "#ccc",
			},
		},
	});

	return (
		
		<ThemeProvider theme={theme}>
			<header>
				<div className={classes.heroContent}>
					<Container maxWidth="sm">
						<img src={logo} className={classes.logo} alt="Logo"></img>
						<Typography variant="h5" align="center" color="inherit" paragraph>
							<span className={classes.inverted}>Fria Folket</span> arbetar med skal och rum och är en plattform för experiment, projekt och verk inom arkitektur, eller textil eller den anda som faller på.
						</Typography>
						<ScrollTo>
							{({ scroll }) => (
							<Button className={classes.heroButtom} variant="contained" color="secondary" onClick={() => scroll({ x: 0, y: document.body.scrollHeight, smooth: true })}>Kontakt</Button>
							)}
						</ScrollTo>
					</Container>
				</div>
			</header>
		</ThemeProvider>
	);
}

export default Header;
