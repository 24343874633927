import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const Footer = () => {

	const useStyles = makeStyles((theme) => ({
		root: {
			marginTop: theme.spacing(4),
			background: 'transparent',
			padding: theme.spacing(2)
		},
		address: {
			marginTop: theme.spacing(4),
			padding: theme.spacing(2)
		},
		link: {
			display: "inline-block",
			padding: theme.spacing(2)
		}
	}));

	const classes = useStyles();

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: "#cccccc",
			},
			secondary: {
				main: "#cccccc",
			},
		},
	});

	return (
		
		<ThemeProvider theme={theme}>
			<div className={classes.address} id="mainFooter">
				<p>Hanna Michelson Arkitekt SAR/ MSA</p>
				<p><a href={`mailto:hanna@friafolket.se?subject=Hello!`}>hanna@friafolket.se</a></p>
				<p>+46(0)70 639 00 39</p>
			</div>
			<div>
				<a className={classes.link} target="_blank" rel="noreferrer" href="https://www.instagram.com/friafolket/?hl=en">
					<InstagramIcon />
				</a>
				<a className={classes.link} target="_blank" rel="noreferrer" href="https://se.linkedin.com/in/hanna-michelson-4194b926">
					<LinkedInIcon />
				</a>
				<a className={classes.link} href={`mailto:hanna@friafolket.se?subject=Hello!`}>
					<MailOutlineIcon />
				</a>
			</div>
		</ThemeProvider>
	);
}

export default Footer;
