import './style/App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Posts from './components/Posts';

const App = () => (

	<div className="App">
		<Header />
		<Posts />
		<Footer />
	</div>
);

export default App;
